export default [
    {
        name: "home",
        path: "/",
        file: "/home",
        alias: ["/vi"],
    },
    {
        name: "page",
        path: "/page/terms-of-use",
        file: "/page",
        alias: ["/vi/trang/dieu-khoan-su-dung"],
    },
    {
        name: "page",
        path: "/page/privacy-policy",
        file: "/page",
        alias: ["/vi/trang/chinh-sach-bao-mat"],
    },
]
