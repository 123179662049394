<template>
  <div
    ref="el"
    class="header w-full h-18 lg:h-22.5 bg-primary fixed inset-0 z-10"
  >
    <div class="container h-full">
      <!-- Header desktop -->
      <div class="h-full hidden lg:flex items-center justify-between">
        <nuxt-link to="/" class="w-[136px]">
          <app-image
            :id="dataConfig?.app_configs?.logo"
            class="w-full"
            width="200"
            height="60"
            alt="logo"
          />
        </nuxt-link>
        <ul class="flex gap-4 lg:gap-10 items-center h-full">
          <li v-for="(item, index) in menuHeader?.menu_item" class="">
            <a
              :href="item?.url"
              class="item-link block text-secondary-300 text-sm leading-normal py-7.75 duration-200 hover:text-white"
            >
              {{ item?.name }}
            </a>
          </li>
        </ul>
        <div ref="langRef" class="flex items-center gap-3">
          <div
            @click="handleShowListLanguage"
            class="flex items-center gap-2 relative cursor-pointer"
          >
            <icons-global class="w-5 h-5" />
            <div class="text-sm leading-normal uppercase text-white">
              {{ route.params?.lang }}
            </div>
            <icons-arrow-down class="text-white" />
            <div
              class="absolute w-43.5 p-4 left-0 top-11.5 bg-white rounded-2 overflow-hidden flex flex-col gap-1 box-shadow-1"
              :class="
                showSelectLang ? 'opacity-100 visible' : 'opacity-0 invisible'
              "
            >
              <div
                class="text-secondary-500 text-xs leading-normal tracking-[-0.24px]"
              >
                {{ t("LANGUAGE") }}
              </div>
              <div
                v-for="item in listLanguage"
                :key="item.code"
                @click.stop="handleSelectLanguage(item)"
                :class="{ 'bg-secondary-100': item?.code === activeLang?.code }"
                class="px-3 py-2 rounded-2 text-secondary-500 text-sm leading-[1.42] tracking-[-0.28px]"
              >
                {{ item?.name }}
              </div>
            </div>
          </div>
          <a
            :href="dataConfig?.link_button"
            target="_blank"
            class="rounded-2 px-6 py-2 border border-solid border-secondary-200 min-w-21.75 h-9.25 flex justify-center items-center text-secondary-200 text-sm leading-[1.5] tracking-[-0.32px] duration-200 cursor-pointer hover:scale-103"
          >
            {{ t("LOGIN") }}
          </a>
          <app-button
            :title="t('REGISTER')"
            target="_blank"
            :link="dataConfig?.link_button"
            class="px-6 py-2 w-full h-9.25 min-w-21.75 !text-sm !font-400"
          />
        </div>
      </div>
      <!-- Header mobile -->
      <div class="h-full lg:hidden flex items-center justify-between">
        <div class="w-[136px]">
          <app-image
            :id="dataConfig?.app_configs?.logo"
            class="w-full"
            width="200"
            height="60"
            alt="logo"
          />
        </div>
        <div ref="langRefMobile" class="flex items-center gap-4">
          <div
            @click="handleShowListLanguage"
            class="flex items-center gap-2 relative cursor-pointer"
          >
            <icons-global class="w-5 h-5" />
            <div class="text-sm leading-normal uppercase text-white">
              {{ route.params?.lang }}
            </div>
            <icons-arrow-down class="text-white" />
            <div
              class="absolute w-43.5 p-4 -left-13 top-11.5 bg-white rounded-2 overflow-hidden flex flex-col gap-1 box-shadow-1"
              :class="
                showSelectLangMobile
                  ? 'opacity-100 visible'
                  : 'opacity-0 invisible'
              "
            >
              <div
                class="text-secondary-500 text-xs leading-normal tracking-[-0.24px]"
              >
                {{ t("LANGUAGE") }}
              </div>
              <div
                v-for="item in listLanguage"
                :key="item.code"
                @click.stop="handleSelectLanguage(item)"
                :class="{ 'bg-secondary-100': item?.code === activeLang?.code }"
                class="px-3 py-2 rounded-2 text-secondary-500 text-sm leading-[1.42] tracking-[-0.28px]"
              >
                {{ item?.name }}
              </div>
            </div>
          </div>
          <div @click="showMenuBar = true" class="">
            <icons-menu-bar class="" />
          </div>
        </div>
      </div>
      <div
        class="lg:hidden flex flex-col h-[100dvh] w-full bg-primary z-7 fixed -top-full left-0 p-6 duration-300 ease"
        :class="showMenuBar ? '!top-0' : ''"
      >
        <div class="flex justify-between items-center mb-4">
          <div class="text-white text-2xl">Menu</div>
          <div @click="showMenuBar = false" class="">
            <icons-x class="w-6 h-6" />
          </div>
        </div>
        <div class="w-[calc(100%+48px)] -mx-6 mb-8 h-0.25 bg-[#4E47D9]"></div>
        <ul class="flex flex-col gap-8">
          <li v-for="(item, index) in menuHeader?.menu_item" class="">
            <a
              :href="item?.url"
              @click="showMenuBar = false"
              class="item-link block text-secondary-300 text-base xl:text-4.5 leading-normal duration-200 hover:text-white"
            >
              {{ item?.name }}
            </a>
          </li>
        </ul>
        <a
          :href="dataConfig?.link_button"
          target="_blank"
          class="rounded-2 mt-6 px-6 py-2 border border-solid border-secondary-200 w-full h-9.25 flex justify-center items-center text-secondary-200 text-sm leading-[1.5] tracking-[-0.32px] duration-200 cursor-pointer hover:scale-103"
        >
          {{ t("LOGIN") }}
        </a>
        <app-button
          :title="t('REGISTER')"
          target="_blank"
          :link="dataConfig?.link_button"
          class="mt-4 px-6 py-2 w-full h-9.25 !text-sm !font-regular"
        />
      </div>
      <div
        class="bg-[rgba(0,0,0,0.5)] w-full h-screen z-6 fixed inset-0 duration-300 ease"
        :class="showMenuBar ? 'opacity-100 visible' : 'opacity-0 invisible'"
        @click="showMenuBar = false"
      ></div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { handleChangeRouter } = formatLanguage();

const route = useRoute();
const { t } = useI18n();

const appConfig = useState("app_config");
const dataConfig = appConfig.value?.data;

const menuHeader = computed(() =>
  dataConfig?.menus?.find(
    (item) => item.name === `header_${route.params?.lang}`
  )
);

const showSelectLang = ref(false);
const showSelectLangMobile = ref(false);

const configStore = useConfigStore();
const listLanguage = ref(configStore.languages);

const activeLang = computed(() =>
  listLanguage.value.find((item) => item?.code?.includes(route.params?.lang))
);

const handleSelectLanguage = (data: any) => {
  showSelectLang.value = false;
  showSelectLangMobile.value = false;
  handleChangeRouter(data.code);
};

const langRef = ref<HTMLElement | null>(null);
const langRefMobile = ref<HTMLElement | null>(null);
const handleClickOutside = (event: MouseEvent) => {
  if (langRef.value && !langRef.value.contains(event.target as Node)) {
    showSelectLang.value = false;
  }
  if (
    langRefMobile.value &&
    !langRefMobile.value.contains(event.target as Node)
  ) {
    showSelectLangMobile.value = false;
  }
};

const handleShowListLanguage = () => {
  showSelectLang.value = !showSelectLang.value;
  showSelectLangMobile.value = !showSelectLangMobile.value;
};

function functionScroll() {
  const section = document.querySelectorAll(".section");
  const sections: Record<string, number> = {};
  let i: string;
  section.forEach((e) => {
    sections[(e as HTMLElement).id] = (e as HTMLElement).offsetTop;
  });
  const header = document.querySelector(".header") as HTMLElement;
  const height = header?.offsetHeight;
  for (i in sections) {
    if (sections[i] <= window.pageYOffset + height) {
      const actives = document.querySelectorAll(".active");
      if (actives) {
        actives.forEach((item) => {
          item.classList.remove("active");
        });
      }
      const links = document.querySelectorAll(`a[href*=${i}]`);

      if (links) {
        links.forEach((item) => {
          item.classList.add("active");
        });
      }
    }
  }
}

onMounted(() => {
  functionScroll();
  document.addEventListener("click", handleClickOutside);
  var menuLinks = document.querySelectorAll(".item-link");
  menuLinks.forEach(function (link) {
    link.addEventListener("click", function (e) {
      var href = this.getAttribute("href");
      var targetElement = document.querySelector(href);
      var offsetTop = 0;
      screen.width <= 1024
        ? (offsetTop = targetElement.offsetTop - 60)
        : (offsetTop = targetElement.offsetTop - 89);
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
      e.preventDefault();
    });
  });
  window.addEventListener("scroll", functionScroll);
  window.addEventListener("resize", functionScroll);
});

const showMenuBar = ref(false);

watch(showMenuBar, (newValue) => {
  if (newValue) {
    document.body.classList.add("unscroll");
  } else {
    document.body.classList.remove("unscroll");
  }
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});
</script>
<style scoped>
.item-link.active {
  color: white;
}
</style>
