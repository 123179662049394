import dataRouter from '../routers'
import { useCheckLocationPage } from "~/stores/location-page"

export const formatLanguage = () => {
    const configStore = useConfigStore();
    const checkLocationPage = useCheckLocationPage();

    const route = useRoute() 
    const router = useRouter() 

    const getPrefix = (languages_code: String) => {
        return languages_code.split("-")[0];
    };

    const createArrayLanguage = (lang: string) => {
        let namePage = checkLocationPage.handleGetLocationPage()
        let slugPage = checkLocationPage.handleGetSlug()
        const routerPage = Object.values(dataRouter).flat().find(route => route.name === namePage);
        const listDataLang = configStore.languages.map((e:any) => {
            return {
                code: getPrefix(e?.code),
            }
        })

        
        return configStore.languages.map(langItem => {
            let _lang = listDataLang.find(item => item.code === langItem.code.split('-')[0])?.code
            if(slugPage) {
                const matchedSlugPage = slugPage.find((item: any) => item.languages_code === langItem.code);
                const _url = langItem.code.split('-')[0] === 'en' ? routerPage.path : routerPage?.alias.find(path => path.split('/')[1] === _lang)
                return {
                    lang_code: langItem.code,
                    lang: _lang,
                    active: langItem.code.split('-')[0] === lang,
                    url: _url.replace(':slug', matchedSlugPage.slug).replace(':id', matchedSlugPage.id.toString())
                }
            }
            else {
                return {
                    lang_code: langItem.code,
                    lang: _lang,
                    active: langItem.code.split('-')[0] === lang,
                    url: langItem.code.split('-')[0] === 'en' 
                        ? routerPage.path
                        : routerPage?.alias.find(path => path.split('/')[1] === _lang)
                };
            }
        });
    };

    const handleChangeRouter = (languages_code: String) => {
        let lang = getPrefix(languages_code)
        const dataArrayLanguage = createArrayLanguage(lang)
        const languageActive = dataArrayLanguage.find((e:any) => e?.active === true)
        configStore.setCurrentLanguage(lang);          
        router.push(languageActive.url)  
    }
    return {
        handleChangeRouter,
        createArrayLanguage,
    }
}
